import './css/style.css';
import './css/jquery-eu-cookie-law-popup.css';
import 'ol/ol.css';

import './jquery-global.js';

var jquery = require("jquery");
window.$ = window.jQuery = jquery; 

//import bootstrap from 'bootstrap';
require ("./js/jquery-eu-cookie-law-popup.js");

import {Map, View, Overlay} from 'ol';
import TileLayer from 'ol/layer/Tile';

// i18next
import i18next from "i18next";
//import SyncBackend from 'i18next-fs-backend';
//import Fetch from 'i18next-fetch-backend';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import jqueryI18next from "jquery-i18next";


import {Icon, Stroke, Style, Fill, Circle} from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {OSM, XYZ, WMTS} from 'ol/source';
import OSMXML from 'ol/format/OSMXML';
import GeoJSON from 'ol/format/GeoJSON';

import WMTSTileGrid from 'ol/tilegrid/WMTS';

//import GPX from 'ol/format/GPX';
import {transform, transformExtent} from 'ol/proj';
//import MousePosition from 'ol/control/MousePosition';
//import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
//import {createStringXY} from 'ol/coordinate';
import Attribution from 'ol/control/Attribution';
import FullScreen  from 'ol/control/FullScreen';
//import Zoom from 'ol/control/Zoom';
//import {defaults as defaultInteractions} from 'ol/interaction';
import * as olExtent from 'ol/extent';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import Feature from 'ol/Feature';
//import {defaults as interactiondefaults} from 'ol/interaction';
//import ExtentInteraction from 'ol/interaction/Extent';
//import {shiftKeyOnly} from 'ol/events/condition';

import {lineoffset} from './js/offset.js';


   var isMobile,
       map,
       app_variant,
       app_title,
       app_language,
       center_lon, center_lat,
       layerTracks,
       layerNames = [
        'OSMMapnik',
        'OpenCyclemap',
        'CyclOSM',
        'IGNTopo',
        'IGNOrtho',
        //'OpenMapSurfer'
       ],
       clefIGN ="kxgmgm4gdf11oerj2jmgn48t";

$(document).ready(function() {  
   isMobile = window.matchMedia("only screen and (max-width: 760px)");

   var fname = './config/config.txt';
   $.get({url: fname, async: false, success: function(data) {
      var lines = data.split("\n");
      [app_variant, app_title, app_language, center_lat, center_lon] = lines[0].split(',');
      document.title = app_title;
   }});

   i18next.use(HttpApi);
   i18next.use(LanguageDetector);
   i18next
      .init({
         lng: app_language,
         ns:'translation',
         backend: {
             loadPath: './locales/{{lng}}/{{ns}}.json',
           },
         detection: {
            order: ['htmlTag', 'navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage',  'path', 'subdomain']
         },
         debug: true
      })
      .then(function() { 
         jqueryI18next.init(i18next, $, {
           tName: 't', // --> appends $.t = i18next.t
           i18nName: 'i18n', // --> appends $.i18n = i18next
           handleName: 'localize', // --> appends $(selector).localize(opts);
           selectorAttr: 'data-i18n', // selector for translating elements
           targetAttr: 'i18n-target', // data-() attribute to grab target element to translate (if different than itself)
           optionsAttr: 'i18n-options', // data-() attribute that contains options, will load/set if useOptionsAttr = true
           useOptionsAttr: false, // see optionsAttr
           parseDefaultValueFromContent: true // parses default values from content ele.val or ele.text
         });
         $("meta[name='description']").attr("content", $.t("title.description"));
         $("meta[name='Keywords']").attr("content", $.t("title.keywords"));
         $("#head").localize();
         $("#topbar").localize();
         $("#sidebar").localize();
         if (i18next.language == 'fr'){
            $('.checkbox#bicycle_roads').css('display', 'none');
            $('.checkbox-text#bicycle_roads').css('display', 'none');
         }
         $('#sidebar').css('visibility', 'visible');
      })
      .then( function() {init()}
      )

});

var popupOverlay, popupContainer, popupContent, popupCloser, 
    hoverOverlay, hoverContainer, hoverContent;

function init (){

   //==============================================================================
   // PopUp
   //==============================================================================

   /**
    * Elements that make up the popup.
    */
   popupContainer = document.getElementById('popup');
   popupContent = document.getElementById('popup-content');
   popupCloser = document.getElementById('popup-closer');

   hoverContainer = document.getElementById('hover');
   hoverContent = document.getElementById('hover-content');

   /**
    * Create an overlay to anchor the popup to the map.
    */
   popupOverlay = new Overlay({
     element: popupContainer,
     stopEvent: false,              // enable event propagation
     autoPan: true,                 // map slips if popup outside of the current viewmap
     autoPanAnimation: {
       duration: 250
     }
   });

   hoverOverlay = new Overlay({
     element: hoverContainer,
     autoPan: false,                   // popup may be outside of the map
     autoPanAnimation: {
       duration: 250
     }
   });


   /**
    * Add a click handler to hide the popup.
    * @return {boolean} Don't follow the href.
    */
   popupCloser.onclick = function() {
      popupOverlay.setPosition(undefined);
      popupCloser.blur();
      map.on('pointermove', clickHandler);
      popupActive = false;
      return false;
   };
   
var resolutions = [
    156543.03392804103,
    78271.5169640205,
    39135.75848201024,
    19567.879241005125,
    9783.939620502562,
    4891.969810251281,
    2445.9849051256406,
    1222.9924525628203,
    611.4962262814101,
    305.74811314070485,
    152.87405657035254,
    76.43702828517625,
    38.218514142588134,
    19.109257071294063,
    9.554628535647034,
    4.777314267823517,
    2.3886571339117584,
    1.1943285669558792,
    0.5971642834779396,
    0.29858214173896974,
    0.14929107086948493,
    0.07464553543474241
] ;
   //==============================================================================
   // Map
   //==============================================================================
   var baseLayers = [
      new TileLayer({
          source: new OSM,
          visible: true,
         //source: new XYZ({
         //   url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        //}),
      }),
      new TileLayer({
         source: new XYZ({
            url: 'https://{a-c}.tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=7742baa069144391a74ce615f3efb78b',
            attributions: 
               [$.t("map.map") + " © <a href='https://www.thunderforest.com/'>Thunderforest</a>",
                $.t("map.map_data") + " © <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors"
               ],
         }),
         visible: false,
      }),
      new TileLayer({
         source: new XYZ({
            url: 'https://{a-c}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
            attributions: 
               [$.t("map.map") + " © <a href='https://github.com/cyclosm/cyclosm-cartocss-style'>CyclOSM</a>",
                $.t("map.map_data") + " © <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors"
               ],
         }),
         visible: false,
      }),
      
      new TileLayer({
         source: new WMTS({
            url: 'https://wxs.ign.fr/'+clefIGN+'/wmts',
            layer: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
            matrixSet: 'PM',
            format: 'image/png',
            projection: 'EPSG:3857',
            tileGrid: new WMTSTileGrid({
                        origin: [-20037508,20037508], // topLeftCorner
                        resolutions: resolutions, // résolutions
                        matrixIds: ["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19"] // ids des TileMatrix
                    }),
            style: 'normal',
            attributions:
             '<a href="http://www.ign.fr" target="_blank">' +
             '<img src="https://wxs.ign.fr/static/logos/IGN/IGN.gif" title="Institut national de l\'' +
             'information géographique et forestière" alt="IGN"></a>',
         }),
         visible: false,
      }),
      new TileLayer({
         source: new WMTS({
            url: 'https://wxs.ign.fr/'+clefIGN+'/wmts',
            layer: 'ORTHOIMAGERY.ORTHOPHOTOS',
            matrixSet: 'PM',
            format: 'image/jpeg',
            projection: 'EPSG:3857',
            tileGrid: new WMTSTileGrid({
                        origin: [-20037508,20037508], // topLeftCorner
                        resolutions: resolutions, // résolutions
                        matrixIds: ["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19"] // ids des TileMatrix
                    }),
            style: 'normal',
            attributions:
             '<a href="http://www.ign.fr" target="_blank">' +
             '<img src="https://wxs.ign.fr/static/logos/IGN/IGN.gif" title="Institut national de l\'' +
             'information géographique et forestière" alt="IGN"></a>',
         }),
         visible: false,
      }),
   
      //new TileLayer({
      //   source: new XYZ({
      //      url: 'https://api.openrouteservice.org/mapsurfer/{z}/{x}/{y}.png?api_key=5b3ce3597851110001cf62486ab9377e898e4aeeafdeebe36dd0befd',
      //   }),
      //   visible: false,
      //})
   ];
   
   // Track layer
   layerTracks = new VectorLayer({
      source: new VectorSource(),
      zIndex: 300,
   });

   var zoom = 12;
   var center = transform([center_lon, center_lat], 'EPSG:4326', 'EPSG:3857');

   //------------------------------------------------------------------------------
   // try to restore center, zoom-level and rotation from the URL
   //------------------------------------------------------------------------------
   if (window.location.hash !== '') {
      var hash = window.location.hash.replace('#map=', '');
      var parts = hash.split('/');
      if (parts.length === 4) {
         zoom = parseInt(parts[0], 10);
         center = [
               parseFloat(parts[1]),
               parseFloat(parts[2])
         ];
        }
    }

/*
    var mousePositionControl = new MousePosition({
        coordinateFormat: createStringXY(4),
        projection: 'EPSG:4326',
        undefinedHTML: '&nbsp;'
    });
*/
   
   
   //------------------------------------------------------------------------------
   // create map
   //------------------------------------------------------------------------------
    map = new Map({
        target: 'map',
        layers: baseLayers,
        overlays: [popupOverlay,hoverOverlay],
        view: new View({
            center: center,
            projection: 'EPSG:3857',
            minZoom: 8,
            maxZoom: 19,
            zoom: zoom,
            constrainResolution: true,
            smoothResolutionConstraint: false,
        }),
        controls: [
                new Attribution({
                    collapsible: false,
                }),
                new FullScreen(),
            ],
//        interactions: [
//            new MouseWheelZoom({
//                duration: 1000,
//                timeout: 1000,
//            })
//        ],
   });

   map.addLayer(layerTracks);

/*
   helpButton.onclick = function() {
      helpContainer.className += " active";
      helpContent.className += " active";
   };

   helpCloser.onclick = function() {
      helpContainer.classList.remove("active");
      helpContent.classList.remove("active");
   };
*/

    createBorders();

    // display popup on click
    map.on('singleclick', clickHandler);
    map.on('pointermove', clickHandler);

    //------------------------------------------------------------------------------
    // put current map state in url
    //------------------------------------------------------------------------------
    var shouldUpdate = true;
    var view = map.getView();
    map.on('moveend', function() {
        if (!shouldUpdate) {
           // do not update the URL when the view was changed in the 'popstate' handler
           shouldUpdate = true;
           return;
        }

         var center = view.getCenter();
         var hash = '#map=' +
             view.getZoom() + '/' +
             Math.round(center[0] * 100) / 100 + '/' +
             Math.round(center[1] * 100) / 100 + '/' +
             view.getRotation();
         var state = {
           zoom: view.getZoom(),
           center: view.getCenter(),
           rotation: view.getRotation()
         };
         window.history.pushState(state, 'map', hash);

         var viewExtent = map.getView().calculateExtent(map.getSize());     
         transformExtent(viewExtent, 'EPSG:3857', 'EPSG:4326');

    });

      
//------------------------------------------------------------------------------
// jQuery Stuff
//------------------------------------------------------------------------------

//


   

   //------------------------------------------
   // Josm Button
   //------------------------------------------
   $( "#josm-button" ).click(function() {
      var viewExtent = map.getView().calculateExtent(map.getSize());     
      viewExtent = transformExtent(viewExtent, 'EPSG:3857', 'EPSG:4326');

      var josm_remote_link = "http://127.0.0.1:8111/load_and_zoom"
                                 + "?left=" + viewExtent[0] 
                                 + "&right=" + viewExtent[2] 
                                 + "&top=" + viewExtent[3] 
                                 + "&bottom=" + viewExtent[1];
      //alert( "Handler for .click() called." );
         $.ajax({url: josm_remote_link, 
                 error: function(t){
                        if (t.status!=200) alert("JOSM remote control did not respond (" + t.status + "). Do you have JOSM running?");
                     }
                  });
   });
   
   //------------------------------------------
   // Map selection
   //------------------------------------------
   $("#layer-select").change(function onChange() {
     var layerName = $( "#layer-select" ).val();
     for (var i = 0; i < baseLayers.length; i++) {
         if (layerName === layerNames[i]){
             baseLayers[i].setVisible(true)
        }else{
             baseLayers[i].setVisible(false)
        }
     }
   });

   //--------------------------------------------------------
   // Search form
   //--------------------------------------------------------
   //$('#formSearch').submit(function(event) {
   //   event.preventDefault();
   //    txt = $('#search').val();
   //    $.post('search.php', {search: txt},function(result) {
   //      $('span').html(result);
   //    });
   //});

   var winHeight = $(window).height(),
       divHeight = winHeight - 30;

   $('#map').height(divHeight);
   $('#sidebar').height(divHeight);
   $('#sidebarMini').height(divHeight);
   $(window).bind('resize', function() {
      $('#map').height(divHeight);
      $('#sidebar').height(divHeight);
      $('#sidebarMini').height(divHeight);
   });
   map.updateSize();

   // Sidebar Open/Close
   $('#closeSidebar').click(function() {
      $('#sidebar').css('visibility', 'hidden');
      $('#map').css('left', '20px');
      map.updateSize();
   });

   $('#openSidebar').click(function() {
      $('#sidebar').css('visibility', 'visible');
      $('#map').css('left', '400px');
      map.updateSize();
   });

   // Sidebar Container
   $('.trigger').not('.trigger_active').next('.toggle_container').hide();
   $('.trigger').click(function() {
      var trig = $(this);
      if (trig.hasClass('trigger_active')) {
         trig.next('.toggle_container').slideToggle('800');
         trig.removeClass('trigger_active');
      } else {
         $('.trigger_active').next('.toggle_container').slideToggle('800');
         $('.trigger_active').removeClass('trigger_active');
         trig.next('.toggle_container').slideToggle('800');
         trig.addClass('trigger_active');
      }
      return false;
   });

   //--------------------------------------------------------
   // Equipment Selector
   //--------------------------------------------------------
   // Set all checkboxes to unchecked on startup
   $('input[type=checkbox][class=poi]').each(
      function() {
      $(this).prop('checked', false);
     }
   );
   $('input[type=checkbox][class=geojson]').each(
      function() {
         $(this).prop('checked', false);
      }
   );
  
   $('#formEquipment').click(function() {
      // Vector layers
      $('input[type=checkbox][class=poi]').each(
        function() {
          createLayerOverlay($(this).attr('class'), $(this).attr('id'), $(this).is(':checked'));
        }
      );
      $('input[type=checkbox][class=geojson]').each(
        function() {
          createLayerOverlay($(this).attr('class'), $(this).attr('id'), $(this).is(':checked'));
        }
      );
    });

   //--------------------------------------------------------
   // Routes
   //--------------------------------------------------------
   // Set all checkboxes to unchecked on startup
   $('input[type=checkbox][class=route]').each(
      function() {
      $(this).prop('checked', false);
     }
    );
   $('#routeSelect').click(function() {
      $('input[type=checkbox][class=route]').each(function() {
         if ($(this).is(':checked')) {
            // checked item
               addTrack($(this).attr('relId'), $(this).attr('name'), $(this).attr('data-color'));
         }else {
             // unchecked item
              removeTrack($(this).attr('relId'));
         }
      });
    });
   // Select/Unselect all routes
   $('#selectAll').click(function() {
      $('input[type=checkbox][class=route]').each(
         function() {
         $(this).prop('checked', true);
        }
       );
   });

   $('#selectNone').click(function() {
      $('input[type=checkbox][class=route]').each(
         function() {
         $(this).prop('checked', false);
        }
       );
   });

    //--------------------------------------------------------
    // Optional sidebar
    //--------------------------------------------------------
    $('#sidebar2').hide();

    $('.trackInfo a').click(function() {
       if ($('#sidebar2').is(':hidden')) {
          $('#map').css('left', '340px');
          $('#sidebar2Content').html('test text text Text');
         $('#sidebar2').slideToggle('slow');
      }else {
         $('#sidebar2').slideToggle('slow');
         $('#map').css('left', '170px');
      }
    });

    $('a.sidebar2Close').click(function() {
      $('#sidebar2').slideToggle('slow');
      $('#map').css('left', '170px');

    });
    
   // Help Popups
   $(".help-open").on("click", function(){
      var id = $(this).attr('id');
      $("#"+id+".help-popup-overlay, "+"#"+id+".help-popup-content").addClass("active");
   });
   $(".help-close, .help-popup").on("click", function(){
      var id = $(this).attr('id');
      $("#"+id+".help-popup-overlay, "+"#"+id+".help-popup-content").removeClass("active");
   });
};








//------------------------------------------------------------------------------
function clickHandler(evt) {
//------------------------------------------------------------------------------
  var feature,
      features = [];

   //var hitTol = 8;
   var hitTol = 8;
   if (isMobile.matches){
      hitTol = 15;
   }
   map.forEachFeatureAtPixel(evt.pixel,
      function(feature) {
          features.push(feature);
   }, {hitTolerance: hitTol});

   for (var i = 0, ii = features.length; i < ii; ++i) {
      if (feature) {
         var t1 = feature.getGeometry().getType();
         var t2 = features[i].getGeometry().getType();
         if (t1 == 'LineString' && t2 == 'Point') {
            feature = features[i];
         }
      } else {
         feature = features[i];
      }
   }

   if (feature) {
      var prop = feature.getProperties();
      if (   typeof prop['amenity'] !== 'undefined'  
          || typeof prop['shop'] !== 'undefined' 
          || prop['information'] === 'guidepost' 
          || prop['type'] === 'route') {

         if (evt.type === 'singleclick') {
            popupContent.innerHTML = getPopupContent(prop, true);
            $( "#josmlink" ).on( "click", {id: prop["osmid"]}, function(event) {
               var josm_remote_link = "http://127.0.0.1:8111/load_object?new_layer=false" + "&objects=" + event.data.id;
               $.ajax({url: josm_remote_link, 
                       error: function(t){
                           if (t.status!=200) alert("JOSM remote control did not respond (" + t.status + "). Do you have JOSM running?");
                       }
               });
            });
            popupOverlay.setPosition(evt.coordinate);
            hoverOverlay.setPosition(undefined);
         }else{
            hoverContent.innerHTML = getPopupContent(prop, false);
            popupOverlay.setPosition(undefined);
            hoverOverlay.setPosition(evt.coordinate);
            if (prop['type'] === 'route') {
               highlightTrack(prop['relationId'],true);
            }
         }
         if (evt.type === 'singleclick') {
            // Disable popup on hover
            map.un('pointermove', clickHandler);
         }
      }else{
         popupOverlay.setPosition(undefined);
         hoverOverlay.setPosition(undefined);
         map.on('pointermove', clickHandler);
         highlightTrack('',false);
      }
   } else {
      popupOverlay.setPosition(undefined);
      hoverOverlay.setPosition(undefined);
      map.on('pointermove', clickHandler);
      highlightTrack('',false);
   }

};


function getPopupContent (prop, josm){
   var email = prop['email'];
   if (email == undefined) email = prop['contact:email'];
   var phone = prop['phone'];
   if (phone == undefined) phone = prop['contact:phone'];
   var website = prop['website'];
   if (website == undefined) website = prop['contact:website'];
   var content = '';
   //var element = document.getElementById('popup');
   if (typeof prop['amenity'] !== 'undefined') {
      if (prop['amenity'] == 'bicycle_parking') {
         content += '<h1>' + $.t("popup.bicycle_parking") +'</h1>';
         content += "<p>" + $.t("popup.type");
         content += $.t(["popup." + prop['bicycle_parking'], "popup.type_unknown"]);
         content += "</p>"; 
         // Access
         var textAccess = "";
         if (i18next.exists(["popup.access_" + prop['access'], "popup.access_unknown"])){
            textAccess = $.t(["popup.access_" + prop['access'], "popup.access_unknown"]);
         }else if (prop['access']){
            textAccess = prop['access'];
         }
         if (textAccess != ''){
            content += "<p>" + $.t("popup.access") + textAccess + "</p>"; 
         }
      }else if (prop['amenity'] == 'bicycle_rental') {
         content += '<h1>' + $.t("popup.bicycle_rental") + '</h1>';
         if (prop['name']) {
            content = content + '<p>' + prop['name'] + '</p>';
         }
      }else if (prop['amenity'] == 'bicycle_repair_station'){
         if (prop['name']) {
            content += '<h1>' + prop['name'];
         }else{
            content += '<h1>' + $.t("popup.bicycle_repair");
         }
         content += '</h1>';
         var first = 1;
         if (prop["service:bicycle:tools"] === 'yes') {
            if (first){
               content += '<p>';
               first = 0;
            }else{
               content += ', ';
            }
            content += $.t("popup.repair_tools");
         }
         if (prop["service:bicycle:pump"] === 'yes') {
            if (first){
               content += '<p>';
               first = 0;
            }else{
               content += ', ';
            }
            content += $.t("popup.repair_pump");
         }
         if (!first){
            content += '</p>';
         }
      }else if (prop['amenity'] == 'vending_machine'){
         if (prop["vending"] === 'bicycle_tube') {
            if (prop['name']) {
               content += '<h1>' + prop['name'];
            }else{
               content += '<h1>' + $.t("popup.vending_tube");
            }
            content += '</h1>';
         }
      }else if (prop['amenity'] == 'car_sharing') {
         content += '<h1>' + $.t("Station d'autopartage") + '</h1>';
         if (prop['name']) {
            content += '<p>' + prop['name'] + '</p>';
         }
      }else if (prop['amenity'] == 'parking' || prop['amenity']=="car_pooling") {
         var cp = 0;
         var pr = 0;
         content = '<h1>' 
         if (prop['name']) {
            content += prop['name'];
         }else{
            if (prop['amenity']=="car_pooling"){
               // Carpooling
               content += $.t("popup.carpool");
               cp = 1
            }else if (prop['park_ride'] && prop['park_ride'] !== 'no'){
               // Park&Ride
               content += $.t("popup.park_ride");
               pr = 1
            }else if (prop['carpool'] && prop['carpool'] !== 'no'){
               // Carpooling
               content += $.t("popup.carpool");
               cp = 1
            }
         }
         content += '</h1>';
         if (!pr && prop['park_ride'] && prop['park_ride'] !== 'no'){
             content += '<p>' + $.t("popup.park_ride") + $.t("colon") + $.t("yes") + '</p>';
         }
         if (!cp && prop['carpool'] && prop['carpool'] !== 'no'){
             content += '<p>' + $.t("popup.carpool") + $.t("colon") + $.t("yes") + '</p>';
         }
         if (prop['parking']){
              if (prop['parking'] === 'multi-storey' && i18next.exists("popup.multi_storey")){
                  content += '<p>' + $.t("popup.type") + $.t("popup.multi_storey") + '</p>';
              } else if (prop['parking'] === 'surface' && i18next.exists("popup.surface")){
                  content += '<p>' + $.t("popup.type") + $.t("popup.surface") + '</p>';
              }else{
                  content += prop['parking']
              }
         }
      }
   }else if (prop['shop'] == 'bicycle' || prop['shop'] == 'sports' ) {
      if (prop['service:bicycle:diy'] == 'yes') {
         //Selbsthilfewerkstatt
         if (prop['name']) {
            content += '<h1>' + prop['name'] + '</h1>';
            content = content + '<p>' + $.t("popup.diy") + '</p>';
         }else {
            content += '<h1>' + $.t("popup.diy") + '</h1>';
         }
      }else {
         //normaler Fahradladen
         if (prop['name']) {
            content += '<h1>'+prop['name']+'</h1>';
         }else {
            content += '<h1>'+$.t("popup.shop")+'</h1>';
         }
      }
   }else if (prop['information'] === 'guidepost') {
      var d = {n: "north", ne: "northeast", nw: "northwest", e: "east", w: "west", se: "southeast", sw: "southwest", s: "south"};
      var a = {n: "↑", ne: "↗", nw: "↖", e: "→", w: "←", se: "↘", sw: "↙", s: "↓"};
      var x;
      for (x in d) {
         var y = "direction_" + d[x];
         if (prop[y]) {
            var s = prop[y].split(";");
            for (var i = 0; i < s.length; i++) {
               content += "<p>" + a[x] + " " + s[i] + "</p>";
            }
         }
      }
      if (content === '') {
         content = $.t("popup.nodata");
      }
   }else {
      if (prop['name']) {
         content += "<h1>" + prop["name"] + "</h1>";
      }
      if (prop['desc']) {
         content = prop['desc'];
      }
   }
   if (prop['operator']) {
      content = content + '<p>' + $.t("popup.operator") + prop['operator'] + '</p>';
   }
   if (prop['brand']) {
      content = content + '<p>' + $.t("popup.brand") + prop['brand'] + '</p>';
   }
   if (prop['network']) {
      content = content + '<p>' + $.t("popup.network") + prop['network'] + '</p>';
   }
   // Capacity
   if (prop['capacity']) {
      content = content + '<p>' + $.t("popup.capacity") + prop['capacity'] + '</p>';
   }
   if (phone != undefined) {
      //content += '<p>Tel : ' + phone + '</p>';
      content += '<p>' + $.t('popup.phone', { number: phone}) +'</p>';
   }
   if (email != undefined) {
      content += '<p><a href="mailto:' + email + '" target="_blank">' + email + '</a></p>';
   }
   if (website != undefined) {
      content += '<p><a href="' + website + '" target="_blank">' + $.t("popup.website") + '</a></p>';
   }
   if (josm){
      content += '<p><a href="#" id="josmlink">JOSM</a>';
      content += '';
   }   
   return content;
}
//------------------------------------------------------------------------------
function createBorders() {
//------------------------------------------------------------------------------
   $.get('files/borders.osm', function(data) {
      var featuresOut = [];
      
      var format = new OSMXML();
      var features = format.readFeatures(data, {featureProjection: 'EPSG:3857'});

      for (var i = 0; i < features.length; i++) {
         if (features[i].getGeometry().getType() === 'LineString') {
            featuresOut.push(features[i]);
         }
      }
      var layer = new VectorLayer({
         source: new VectorSource({
            features: featuresOut,
         }),
         style: new Style({
                  stroke: new Stroke({
                     color: [211, 0, 255, 0.7],
                     width: 1.5,
                     //lineCap: 'butt',
                     //lineDash: [8, 4, 1, 4],
                  })
         }),
         zIndex: 250,
      });
      map.addLayer(layer);
   })
}
//------------------------------------------------------------------------------
function createLayerOverlay(ov_type, id, checked) {
//------------------------------------------------------------------------------
   var i, j,

       vectorStyles = {
      'rentals':
         new Style({
           image: new Icon({
               src: 'img/bicycle_rental.png',
               crossOrigin: 'anonymous',
            })
         }),
      'shops':
         new Style({
           image: new Icon({
               src: 'img/bicycle_shop.png',
               crossOrigin: 'anonymous',
            })
         }),
      'repair_station':
         new Style({
           image: new Icon({
               src: 'img/repair_station.png',
               crossOrigin: 'anonymous',
            })
         }),
      'tube_vending':
         new Style({
           image: new Icon({
               src: 'img/tires.png',
               crossOrigin: 'anonymous',
            })
         }),
      'traffic_calming':
         new Style({
            stroke: new Stroke({
               color: '#007000',
               width: 5,
            })
         }),
      'bicycle_oneway':
         new Style({
            stroke: new Stroke({
               color: '#700000',
               width: 5,
            })
         }),
      'car_sharing':
         new Style({
           image: new Icon({
               src: 'img/car_sharing.png',
               crossOrigin: 'anonymous',
            })
         }),
   };

   var layers = map.getLayers().getArray();
   if (!checked) {
      for (i = 0; i < layers.length; i++) {
         if (layers[i].get('type') === id) {
            var features = layers[i].getSource().getFeatures();
            for (j = 0; j < features.length; j++) {
               layers[i].getSource().removeFeature(features[j]);
            }
            map.removeLayer(layers[i]);
         }
      }
   }else {
      for (i = 0; i < layers.length; i++) {
         if (layers[i].get('type') === id) {
            return;
         }
      }
      //-----------------------------------------------------
      function styleFunction(feature, resolution) {
      //-----------------------------------------------------
         var p = feature.getProperties(),
             type = feature.get('type'),
             lineDash = [],
             cap = 'round';

         if (type == 'cycleways') {
            var h = p['highway'];
                //a = p['accomp'];
            if (p['dashed']) {
               cap = 'butt';
               lineDash = getDash(resolution);
            }
            return new Style({
               stroke: new Stroke({
                  lineDash: lineDash,
                  width: getLineWidth(resolution, 2),
                  //color: 'rgba(0,112,0,0.8)',
                  color: 'rgba(13,70,138,0.8)',
                  lineCap: cap,
                  zIndex: 310,
               })
            });
         }else if (type == 'cyclelanes') {
            if (p['dashed']) {
               cap = 'butt';
               lineDash = getDash(resolution);
            }
            return new Style({
               stroke: new Stroke({
                  lineDash:   lineDash,
                  width: getLineWidth(resolution, 2),
                  color: 'rgba(219,61,61,0.8)',
                  lineCap: 'round',
                  zIndex: 310,
               })
            });
         }else if (type == 'bicycle_roads') {
            return new Style({
               stroke: new Stroke({
                  width: getLineWidth(resolution, 2),
                  color: 'rgba(0,0,255,0.8)',
                  lineCap: 'round',
                  zIndex: 310,
               })
            });
         }else if (type == 'bicycle_oneway') {
            return new Style({
               stroke: new Stroke({
                  //lineDash:   lineDash,
                  width: getLineWidth(resolution, 2),
                  color: 'rgba(112,0,0,0.8)',
                  lineCap: 'round',
                  zIndex: 310,
               })
            });
         }else if (type == 'bicycle_network') {
            var fill = new Fill({
                  color: 'rgba(139,0,139,0.0)'
                });
            var stroke = new Stroke({
                   color: 'rgba(67,121,46,0.9)',
                   width: getLineWidth(resolution,3)
            });
            if (resolution < 10) { // zoom <= 14
                var image = new Icon({
                    src: 'img/information.png',
                    scale: 0.7,
                    crossOrigin: 'anonymous',
                });
            }else{
                var r;
                if (resolution < 20) {  // zoom <= 13
                    r = 4;
                }else{
                    r = 3;
                }
                var image = new Circle({
                    radius: r,
                    fill: new Fill({
                        color: 'rgba(67,121,46,0.9)',
                        opacity: 1
                    })
                })
            }
            return new Style({
                 image: image,
                 fill: fill,
                 stroke: stroke
               });
         }else if (type == 'traffic_calming') {
            var h = p['highway'],
                color = 'rgba(205,38,38,0.8)';
            if (h == 'living_street') {
               cap = 'butt';
               lineDash = getDash(resolution);
            }else if (h == 'pedestrian') {
               cap = 'butt';
               lineDash = getDash(resolution);
               color = 'rgba(77,102,25,0.8)';
            }
            return new Style({
               stroke: new Stroke({
                  lineDash: lineDash,
                  width: getLineWidth(resolution, 2),
                  color: color,
                  lineCap: cap,
                  zIndex: 310,
               })
            });
         }else if (type == 'parkings-simple'){
            return new Style({
               image: new Icon({
                  src: 'img/bicycle_parking.png',
               })
            });
         }else if (type == 'parkings-secure'){
            return new Style({
               image: new Icon({
                  src: 'img/bicycle_parking_lockers.png',
               })
            });
         }else if (type == 'park_ride'){
            var img = 'img/parkandride.png'
            if (p['amenity']=='car_pooling' || (p['carpool'] && p['carpool'] !== 'no')){
               if (i18next.language == 'fr'){
                 img = 'img/carpoolparking_fr.png'
               }else{
                 img = 'img/carpoolparking.png'
               }
            }
            return new Style({
                image: new Icon({
                    src: img,
                })
            })
         }else if (type == 'hw_minor'){
            return new Style({
               stroke: new Stroke({
                  lineDash: getDash(resolution),
                  width: getLineWidth(resolution, 2),
                  color: 'rgba(0,64,0,0.8)',
                  lineCap: 'butt',
               })
            });
         }else if (type == 'hw_local'){
            return new Style({
               stroke: new Stroke({
                  width: getLineWidth(resolution, 2),
                  color: 'rgba(0,64,0,0.8)',
                  lineCap: 'butt',
               })
            });
         }else if (type == 'hw_track'){
            return new Style({
               stroke: new Stroke({
                  width: getLineWidth(resolution, 2),
                  color: 'rgba(122,19,19,0.8)',
                  lineCap: 'butt',
               })
            });
         }else{
            return vectorStyles[type];
         }
      }
      if (ov_type == 'poi'){
         var fname = './files/' + id + '.osm';
         if (id === 'parkings-simple' || id === 'parkings-secure'){
            fname = './files/parkings.osm';
         }
      }else if (ov_type == "geojson"){
         var fname = './files/' + id + '.geojson';
      }
      $.get(fname, function(data) {
         var featuresOut = [],
             format;

         if (ov_type == 'poi'){
            format = new OSMXML(),
            features = format.readFeatures(data, {featureProjection: 'EPSG:3857'});
         }else if (ov_type == "geojson"){
            format = new GeoJSON(),
            features = format.readFeatures(data, {featureProjection: 'EPSG:3857'});
         }
         for (var i = 0; i < features.length; i++) {
            var p = features[i].getProperties();
            if (((id === 'bicycle_oneway' || id === 'traffic_calming' || id === 'cycleways' || id === 'cyclelanes' || id === 'bicycle_roads') 
                 && features[i].get('highway')) ||
                (id === 'bicycle_network' && (features[i].get('highway') && features[i].getGeometry().getType() === 'LineString' || 
                 features[i].get('information') == "guidepost")) ||
                (id === 'bicycle_routes') ||
                (id === 'shops' && features[i].get('shop')) ||
                ((id === 'rentals' || id === 'parkings-simple' || id === 'parkings-secure' || id === 'car_sharing'|| id === 'tube_vending' || id === 'repair_station')
                  && p['amenity']) ||
                (id === 'park_ride' && (p['amenity'] === 'parking' || p['amenity'] === 'car_pooling')) ||
                (id === "hw_minor" || id === "hw_local" || id === "hw_track")
               ){
               if (features[i].getGeometry().getType() === 'Polygon' && !features[i].get('highway')) {
                  var c = olExtent.getCenter(features[i].getGeometry().getExtent());
                  var point = new Point(c);
                  features[i].setGeometry(point);
                  features[i].set('osmid', 'w' + features[i].getId());
               }else{
                  features[i].set('osmid', 'n' + features[i].getId());
               }
               j++;
               if (id === 'cycleways' || id === 'cyclelanes') {
                  if (features[i].getGeometry().getType() === 'LineString') {
                     features[i].set('type', id);
                     var hw = p['highway'];
                     if (id != 'cyclelanes' && hw.match(/(cycleway|footway|track|path|service)/)) {
                        if (hw == 'cycleway' || p['bicycle'] == 'designated'){
                           features[i].set('dashed', false);
                        }else{
                           features[i].set('dashed', true);
                        }
                       featuresOut.push(features[i]);
                     } else {
                        // Fahrradstreifen und Schutzstreifen
                        var c = 0;
                        var dashed_r = false;
                        var dashed_l = false;
                        if (id === 'cyclelanes'){
                           // strassenbegleitender Radweg/Streifen
                           if ('cycleway' in p){
                              p['cycleway:right'] = p['cycleway'];
                              p['cycleway:left']  = p['cycleway'];
                           }
                           if ('cycleway:both' in p){
                              p['cycleway:right'] = p['cycleway:both'];
                              p['cycleway:left']  = p['cycleway:both'];
                           }
                           if ('cycleway:traffic_sign' in p){
                              p['cycleway:right:traffic_sign'] = p['cycleway:traffic_sign'];
                              p['cycleway:left:traffic_sign']  = p['cycleway:traffic_sign'];
                           }
                           if ('cycleway:both:traffic_sign' in p){
                              p['cycleway:right:traffic_sign'] = p['cycleway:both:traffic_sign'];
                              p['cycleway:left:traffic_sign']  = p['cycleway:both:traffic_sign'];
                           }
                           if ('cycleway:right' in p){
                                if (p['cycleway:right'].match(/lane|share.*/)){
                                    c = c | 1;
                                }
                                if (p['cycleway:right'].match(/share.*/)
                                   || ('cycleway:right:traffic_sign' in p && p['cycleway:right:traffic_sign'] === 'DE:340') ){
                                    dashed_r = true;
                               }
                           }
                           if ('cycleway:left' in p){
                                if (p['cycleway:left'].match(/lane|share.*/)) {
                                    c = c | 2;
                                }
                               if (   p['cycleway:left'].match(/share.*/)
                                   || ('cycleway:left:traffic_sign' in p && p['cycleway:left:traffic_sign'] === 'DE:340') ){
                                    dashed_l = true;
                               }
                           }
                        }else{
                           if (p['cycleway'] === 'track'){
                               c = c | 3;
                           }
                           if (p['cycleway:right'] === 'track'){
                               c = c | 1;
                           }
                           if (p['cycleway:left'] === 'track'){
                               c = c | 2;
                           }
                           if (p['sidewalk:bicycle'] ) {
                              if (p['sidewalk:bicycle'].match(/(yes|designated)/)) {
                                 c = c | 3;
                                 if (p['sidewalk:bicycle'] == "yes") {
                                    dashed_r = true;
                                    dashed_l = true;
                                 }
                             }
                           }
                           if (p['sidewalk:right:bicycle'] ) {
                              if (p['sidewalk:right:bicycle'].match(/(yes|designated)/)) {
                                 c = c | 1;
                                 if (p['sidewalk:right:bicycle'] == "yes") {
                                    dashed_r = true;
                                 }
                              }
                           }
                           if (p['sidewalk:left:bicycle'] ) {
                              if (p['sidewalk:left:bicycle'].match(/(yes|designated)/)) {
                                 c = c | 2;
                                 if (p['sidewalk:left:bicycle'] == "yes") {
                                    dashed_l = true;
                                 }
                              }
                           }
                        }
                        if (c >= 2) {
                           var featureOff = offset(features[i], -5);
                           featureOff.set('type', id);
                           featureOff.set('accomp', true);
                           featureOff.set('dashed', dashed_l);
                           featuresOut.push(featureOff);
                           c -= 2;
                        }
                        if (c == 1) {
                           var featureOff = offset(features[i], 5);
                           featureOff.set('type', id);
                           featureOff.set('accomp', true);
                           featureOff.set('dashed', dashed_r);
                           featuresOut.push(featureOff);
                        }
                     }
                  }
               }else{
                  features[i].set('type', id);
                  if (id === "parkings-simple"){
                     if ( ! (p['bicycle_parking'] === 'lockers' || p['bicycle_parking'] == 'building')){
                        featuresOut.push(features[i]);
                     }
                  }else if (id == "parkings-secure"){
                     if (p['bicycle_parking'] === 'lockers' || p['bicycle_parking'] === 'building'){
                        featuresOut.push(features[i]);
                     }
                  }else{
                     featuresOut.push(features[i]);
                  }
               }
            }
         }
         var layer = new VectorLayer({
            source: new VectorSource({
               features: featuresOut
            }),
            zIndex: 250,
         });
         layer.setStyle(styleFunction);
         layer.set('type', id);
         var min = 40075016.68557849 / 256 / Math.pow(2, 19);
         if (id === 'bicycle_oneway' || id === 'traffic_calming' || id === 'cycleways' || id === 'cyclelanes' || id === 'bicycle_network') {
            var max = 40075016.68557849 / 256 / Math.pow(2, 9);
         }else {
            var max = 40075016.68557849 / 256 / Math.pow(2, 7);
         }
         layer.setMinResolution(min);
         layer.setMaxResolution(max);
         map.addLayer(layer);
      });
   }
}
function offset(featureIn, off) {
   var geom = featureIn.getGeometry(),
       coord = geom.getCoordinates(),
       inLine = [];
   for (var k = 0; k < coord.length; k++) {
      inLine.push({x: coord[k][0], y: coord[k][1]});
   }
   var outLine = lineoffset(inLine, off);
   coord = [];
   for (var k = 0; k < outLine.length; k++) {
      coord.push([outLine[k].x, outLine[k].y]);
   }
   var geomOut = new LineString(coord);
   var featureOut = new Feature();
   featureOut.setProperties(featureIn.getProperties());
   featureOut.setGeometry(geomOut);
   return featureOut;
}

//------------------------------------------------------------------------------
// Overlays mit Track
//------------------------------------------------------------------------------

function addTrack(id, trackname, color) {
   var i;
   var features = layerTracks.getSource().getFeatures();
   for (i = 0; i < features.length; i++) {
      if (features[i].get('relationId') === id) {
         return
      }
   }
   var n = trackname.replace(/ /g, "_");
   var n = './files/tracks/' + n + '.osm';
 
   $.get(n, function(data) {
      var format = new OSMXML();
      var features = format.readFeatures(data, {featureProjection: 'EPSG:3857'});
      var styleStroke = new Stroke({
            width: 5,
            color: [0, 0, 200, 0.6]
      });
      /*
      var styleImage = new Icon({
            src: 'img/iconInfo.png',
      });
      var styleText = new Text({
            font: 'bold 14px Verdana',
            text: trackname,
            fill: new Fill({
               color: [64, 64, 64, 0.9]
            }),
            stroke: new Stroke({
                color: '#fff',
                width: 2
            }), 
            textBaseline: 'ideographic',
      });
      */
      //var extent = ol.extent.createEmpty();
      for (var i = 0; i < features.length; i++) {
         features[i].setStyle(new Style({stroke: styleStroke}));
         features[i].set('relationId', id);
         features[i].set('type', "route");
         features[i].set('name', trackname);
         //if (features[i].getGeometry().getType() === 'MultiLineString') {
         //   ol.extent.extend(extent, features[i].getGeometry().getExtent());
         //}
      }
      layerTracks.getSource().addFeatures(features);

      var extent = layerTracks.getSource().getExtent();

      map.getView().fit(extent, map.getSize());
   }, 'text');
}

function removeTrack(id) {
   var i;
   var features = layerTracks.getSource().getFeatures();
   for (i = 0; i < features.length; i++) {
      if (features[i].get('relationId')) {
         if (features[i].get('relationId') == id) {
            layerTracks.getSource().removeFeature(features[i]);
         }
      }
   }
}

var savedId = 0,
    savedStyle;
function highlightTrack(relationId, highlight) {
   var i;
   if (highlight && relationId === savedId){
      return;
   }
   if (savedId){
      var features = layerTracks.getSource().getFeatures();
      for (i = 0; i < features.length; i++) {
         var id = features[i].get('relationId');
         if (id === savedId) {
            features[i].setStyle(savedStyle);
         }
      }
      savedId = false;
      savedStyle = false;
   }
   if (highlight){     
      var features = layerTracks.getSource().getFeatures();
      for (i = 0; i < features.length; i++) {
         var id = features[i].get('relationId');
         if (id === relationId){
            savedStyle = features[i].getStyle();
            var style = new Style ({
               stroke: new Stroke({
                  width: 8,
                  color: [200, 0, 0, 0.6]
               })
            });
            features[i].setStyle(style);
         }
      }
      savedId = relationId;
   }
}


function getLineWidth(resolution, width) {
   var width;
   if (resolution < 0.6) {       // zoom <= 18
      width *= 4;
   }else if (resolution < 1.5) { // zoom <= 15
      width *= 3;
   }else if (resolution < 10) {  // zoom <= 14
      width *= 2;
   }
   return width;
}

function getDash(resolution) {
   var lineDash;
   if (resolution < 2) {
      lineDash = [16, 8];
   }else if (resolution < 5) {
      lineDash = [8, 4];
   }else if (resolution < 20) {
      lineDash = [4, 2];
   } else {
      lineDash = [4, 2];
   }
   return lineDash;
}


